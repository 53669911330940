<template>
    <div class="outstandings container">
        <div>
            <div class="ui simple middle aligned computer only tablet only two column grid">
                <div class="column">
                    <h3 class="ui header">
                        Outstanding Records
                        <div class="sub header">Customers with outstanding after sales record</div>
                    </h3>
                </div>
                <div class="right aligned column">

                </div>
            </div>
            <div class="ui simple middle aligned mobile only grid">
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="!mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div>
                                    <h3 class="ui header">
                                        Outstanding Records
                                        <div class="sub header">Customers with outstanding after sales record</div>
                                    </h3>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="icon link" @click="mobile_search = true;">
                                        <i class="search icon"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div style="flex: 1; min-width: 0;">
                                    <form class="ui search form" @submit.prevent="search_">
                                        <div class="ui icon fluid input" :class="{loading: loading}">
                                            <input type="text" placeholder="ID, name, birth date(YYYY-MM-DD), contact no..." v-model.trim.lazy="query"/>
                                            <i class="search link icon"></i>
                                        </div>
                                    </form>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="close icon link" @click="mobile_search = false;">
                                        &times;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="ui hidden divider"></div>
        <div>
            <div class="ui two column stackable simple grid">
                <div class="tablet only computer only column">
                    <form class="ui form" @submit.prevent="search_">
                        <div class="ui left icon action fluid input" :class="{loading: loading}">
                            <i class="search icon"></i>
                            <input type="text" placeholder="ID, name, birth date(YYYY-MM-DD), contact no..." v-model.trim.lazy="query"/>
                            <button type="submit" class="ui icon black button">
                                <i class="arrow right icon"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="right aligned column">
                    <div class="ui compact secondary menu">
                        <div ref="sortbydropdown" class="ui inline dropdown item">
                            <input type="hidden" :value="sorting.by" @change="changeSorting"/>
                            <span style="margin-right: 0.5em;">Sort By:</span><div class="text">Default</div>
                            <i class="dropdown icon"></i>
                            <div class="menu">
                                <div class="item" data-value="a.aftersales_datetime">Outstanding Date</div>
                                <div class="item" data-value="customer_outstanding">Outstanding Amount</div>
                                <div class="item" data-value="c.name">Customer Name</div>
                            </div>
                        </div>
                        <button class="ui fitted item button" type="button" title="Ascending" @click="sortAsc"><i class="sort amount up icon" :class="{teal: sorting.order === 'asc'}"></i></button>
                        <button class="ui fitted item button" type="button" title="Descending" @click="sortDesc"><i class="sort amount down icon" :class="{teal: sorting.order === 'desc'}"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-container">
            <div class="ui hidden divider"></div>
            <div class="ui very basic segment" :class="{loading}">
                <table class="ui very basic unstackable responsive main table">
                    <thead>
                        <tr class="top aligned">
                            <th>Customer</th>
                            <th>Treatment</th>
                            <th class="right aligned">Total Outstanding Amount (RM)</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="customers.length">
                        <template v-for="customer in customers">
                            <tr class="top aligned selectable" :key="`customer_tr_${customer.id}`" @click="toggleOutstandingDetail(customer)">
                                <td data-title="Customer">
                                    {{customer.name}}
                                </td>
                                <td data-title="Treatment">
                                    {{customer.prescriptions.length}} outstanding treatment{{customer.prescriptions.length > 1? "s" : ""}}
                                </td>
                                <td data-title="Total Outstanding Amount (RM)" :class="{'right aligned': (!is_sm)}" :style="(is_sm)? {paddingRight: '2em'} : false">
                                    <strong class="ui red text">{{customer.outstanding|tocurrency}}</strong>
                                </td>
                                <td class="right aligned" :style="(is_sm)? {padding: '0'} : false">
                                    <a class="collapse button" href="javascript:void(0);"><i :class="{'angle down icon': customer.expanded, 'angle right icon': !customer.expanded}"></i></a>
                                </td>
                            </tr>
                            <tr class="top aligned positive" :key="`prescription_tr_${customer.id}`" v-show="customer.expanded">
                                <td>
                                    <table class="ui very basic compact no-celled unstackable responsive sub table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <h5 class="ui grey text">Contact Detail</h5>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-show="is_sm">
                                                <td><h5 class="ui grey text">Contact Detail</h5></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="ui relaxed list">
                                                        <div class="item">
                                                            <i class="ui phone darkteal icon text"></i>
                                                            <div class="content">
                                                                <a :href="`tel:${customer.contact_no}`">{{customer.contact_no}}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="ui relaxed list">
                                                        <div class="item">
                                                            <i class="ui envelope darkteal icon text"></i>
                                                            <div class="content">
                                                                <a :href="`mailto:${customer.email}`">{{customer.email}}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="ui relaxed list">
                                                        <div class="item">
                                                            <i class="ui user darkteal icon text"></i>
                                                            <div class="content">
                                                                <router-link :to="{
                                                                    name: 'Customer',
                                                                    params: {
                                                                        id: customer.id
                                                                    }
                                                                }">
                                                                    View Customer Profile
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td colspan="2">
                                    <form @submit.prevent="updateAfterSales(customer)">
                                        <table class="ui very basic compact no-celled unstackable responsive outstanding-detail sub table">
                                            <thead>
                                                <tr class="top aligned">
                                                    <th>
                                                        <h5 class="ui grey text">Main Treatment</h5>
                                                    </th>
                                                    <th class="right aligned">
                                                        <h5 class="ui grey text">Sales Total</h5>
                                                    </th>
                                                    <th class="right aligned">
                                                        <h5 class="ui grey text">Deposit</h5>
                                                    </th>
                                                    <th class="right aligned">
                                                        <h5 class="ui grey text">Outstanding Amount</h5>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="top aligned" v-for="prescription in customer.prescriptions" :key="`prescription_entry_${customer.id}_${prescription.id}`">
                                                    <td>
                                                        <router-link :to="{
                                                            name: 'Prescription',
                                                            params: {
                                                                id: prescription.id
                                                            }
                                                        }">
                                                            <strong>{{prescription.main_treatment_code}}</strong> {{prescription.main_treatment_description}}
                                                        </router-link>
                                                        <label class="ui tiny olive label" v-if="prescription.aftersales.instalment"><i class="redo icon"></i>Instalment</label>
                                                        <div class="ui light text">
                                                            {{prescription.prescription_datetime|formatdate("DD MMM YYYY hh:mmA")}}
                                                        </div>
                                                    </td>
                                                    <td class="right aligned mobile inline" data-title="Sales Total">
                                                        <div :data-tooltip="prescription.aftersales.collection_date? $moment(prescription.aftersales.collection_date).format('D MMM YYYY [at] h:mmA') : false">
                                                            {{prescription.aftersales.sales_total|tocurrency}}
                                                        </div>
                                                    </td>
                                                    <td class="right aligned mobile inline" data-title="Deposit">
                                                        <div class="inline input" :class="{changed: prescription.aftersales.original_deposit !== prescription.aftersales.deposit}">
                                                            <input type="number" step="0.01" :min="0" :max="prescription.aftersales.sales_total" v-model.number="prescription.aftersales.deposit" @focus="$event.target.select()" required/>
                                                        </div>
                                                    </td>
                                                    <td class="right aligned mobile inline" data-title="Outstanding">
                                                        <strong>{{(prescription.aftersales.sales_total - prescription.aftersales.deposit)|tocurrency}}</strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="4" class="right aligned">
                                                        <div class="ui hidden divider"></div>
                                                        <button type="button" class="ui small alt red button" @click="undoChanges(customer)">Undo</button>
                                                        <button type="submit" class="ui small black button">Save Changes</button>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </form>
                                </td>
                                <td></td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">No outstanding record found.</td>
                        </tr>
                    </tbody>
                    <tfoot v-show="customers.length">
                        <tr>
                            <th colspan="4" class="right aligned">
                                <pagination-menu ref="paginationmenu" :total-count="pagination.total_count" :start-page="pagination.page" :page-size="pagination.size" @page-changed="changePage" always-show/>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash.debounce";

import PaginationMenu from "@/components/PaginationMenu";

import { mapActions } from "vuex";

//TODO: transition of list
export default {
    name: "outstandings",
    components: {
        PaginationMenu
    },
    data() {
        return {
            mobile_search: false,
            loading: true,
            query: "",
            customers: [],
            sorting: {
                by: "a.aftersales_datetime",
                order: "desc"
            },
            pagination: {
                total_count: 0,
                page: 1,
                size: 10
            },
            scrollY: window.scrollY || 0,
            search_: debounce(this.search, 100)
        };
    },
    created() {
        this.search_();
    },
    mounted() {
        $(this.$refs.sortbydropdown).dropdown();
    },
    activated() {
        this.search().finally(() => {
            if(this.scrollY) {
                this.$nextTick(() => {
                    window.scroll(0, this.scrollY);
                });
            };
        });
    },
    updated() {
        $(this.$el).find(".ui.action.menu .ui.dropdown").dropdown();
    },
    beforeRouteLeave(to, from, next) {
        this.scrollY = window.scrollY;
        next();
    },
    computed: {
        search_params() {
            let search_params = {
                page: this.pagination.page,
                size: this.pagination.size,
                sort: this.sorting.by,
                order: this.sorting.order
            };

            if(this.query) {
                search_params.query = this.query;
            }

            return search_params;
        }
    },
    methods: {
        search() {
            this.loading = true;

            const params = this.search_params;
            return this.$http.get("outstanding-aftersales", {
                params
            }).then((response) => {
                this.customers = response.data.map((customer) => {
                    customer.prescriptions.forEach((prescription) => {
                        prescription.aftersales.original_deposit = prescription.aftersales.deposit;
                    });

                    return customer;
                });

                const total_count = response.headers["x-total-count"];
                this.pagination.total_count = total_count? +total_count : this.customers.length;
            }).catch((error) => {
                console.error(error);

                this.$toasted.error("Unable to list outstanding records. Please try again later.", {
                    duration: 3000
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        changeSorting(event) {
            this.sorting.by = event.target.value;
        },
        sortAsc() {
            this.sorting.order = "asc";
        },
        sortDesc() {
            this.sorting.order = "desc";
        },
        changePage(page) {
            this.pagination.page = page;
        },
        toggleOutstandingDetail(customer) {
            this.$set(customer, "expanded", !customer.expanded);
        },
        undoChanges(customer) {
            customer.prescriptions.forEach((prescription) => {
                prescription.aftersales.deposit = prescription.aftersales.original_deposit;
            });
        },
        updateAfterSales(customer) {
            let after_sales_array = customer.prescriptions.map((prescription) => {
                return prescription.aftersales;
            });

            this.$http.put("aftersales", after_sales_array).then(() => {
                this.search_();

                this.$toasted.success("Record updated successfully.", {
                    duration: 3000
                });
            }).catch((error) => {
                console.error(error);
                this.$toasted.error("Unable update record. Please try again later.", {
                    duration: 3000
                });
            }).finally(() => {
                this.updateOutstandingCount();
            });
        },
        ...mapActions({
            updateOutstandingCount: "outstanding/fetchUpdate"
        })
    },
    watch: {
        search_params: {
            deep: true,
            handler(search_params, _search_params) {
                if(search_params.query !== _search_params.query) {
                    if(this.pagination.page === 1) {
                        this.search_();
                    } else {
                        this.$refs.paginationmenu.stepTo(1);
                    }
                } else {
                    this.search_();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.outstandings.container {
    background: #fff;
}

.ui.mobile.only.grid {
    position: relative;
}

.mobile.action.links {
    display: inline-flex;
    align-items: flex-start;

    .icon.link {
        font-size: 1.2rem;
        padding: 0.35rem;
        display: inline-block;

        &.plus, &.close {
            font-size: 2.25rem;
        }

        .icon {
            margin: 0;
        }
    }
}

.inline.input {
    padding: 0.25em 0.5em 0.25em 0;
    margin: -0.25em -0.5em -0.25em 0;
    background: #fff;
    box-shadow: inset 0 0 1px #a3a3a3;

    &.changed {
        background: #fef1c9;

        input {
            color: #9e7703;
            font-weight: 700;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;

        text-align: right;
        padding: 0;
        outline: none;
        border: none;
        background: none;
    }
}

@media only screen and (min-width: 768px) {
    .ui.main.table {
        > thead, > tbody {
            > tr {
                > th:first-child, > td:first-child {
                    padding-left: 0.5rem !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .ui.table.responsive.unstackable {
        tbody {
            tr {
                position: relative;

                .collapse.button {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }
        }
    }

    .ui.outstanding-detail.table {
        tr {
            td:first-child {
                margin-top: 1em;
            }
        }
    }

    .list-container {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fbf7f2;/*#fafff8;*/
        border-radius: 20px 20px 0 0;
    }
}
</style>